import {Injectable, OnDestroy} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../services/login.service';
import { Subscription } from 'rxjs/Subscription';
import { User } from '../entities/user';
import { Router } from '@angular/router';
import { SeggacService } from '../services/seggac.service';

declare var $: any;
@Injectable()
export class CanActivateViaAuthGuard implements CanActivate, OnDestroy {
  isLogged: boolean;
  _subscription: Subscription;
  constructor(
    private loginService: LoginService, 
    public _router: Router,
    private seggacService: SeggacService
  ) {
    this.isLogged = loginService.isLogged;
    if (!this.isLogged && sessionStorage.getItem('_userseggac')) {
      const _usu = new User();
      const _userCache = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
      _usu.userdata = _userCache.userdata;
      this.loginService.setUserSeggac(_usu);
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url = state.url;
    const _user = JSON.parse(window.atob(sessionStorage.getItem('_userseggac')));
    let usertypeid = _user && _user.userdata && _user.userdata.usertypeid && _user.userdata.usertypeid.usertypeid;
    usertypeid =  usertypeid.toString();
    if (_user.userdata.userid == 1) {
      return true;
    }
    this.seggacService.checkStatus(url);
    
    if (route.routeConfig.path === 'company/receipt' && route.queryParams['id'] !== undefined) {
      url = '/company/receipt';
    }
    this._subscription = this.loginService.getIsLogged().subscribe(value => {
      this.isLogged = value;
    });
    if (!sessionStorage.getItem('_userseggac')) {
      return false;
    }

    if ((usertypeid === '1' || usertypeid === '2') && (url === '/profile' || url === '/account')) {
      return true;
    }
    if (usertypeid && usertypeid === '1' && url === '/company/servicecli') {
      return true;
    }
    if (usertypeid && (usertypeid === '2' || usertypeid === '1')) {
      const rol = Array.isArray(_user.userdata.roles) && _user.userdata.roles.length > 0 ? _user.userdata.roles[0] : null;
      if (!rol) {
        $.notify({
          message: 'Usuario no tiene roles asignados'
        }, { type: 'warning' });
        return false;
      }
      if (!Array.isArray(rol.modules) || rol.modules.indexOf(url) === -1) {
        $.notify({
          message: 'Rol de usuario no tiene módulo asignado'
        }, { type: 'warning' });
        return false;
      }
      return true;
    } else {
      $.notify({
        message: 'Tipo de usuario no autorizado'
      }, { type: 'warning' });
    }
    return false;
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
